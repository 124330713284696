import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import RenderInput from "./../../common/RenderInput/index";
import RenderTextarea from "./../../common/RenderTextarea/index";
import emailjs from "emailjs-com";
import "./styles.scss";
import CallIcon from "../../assets/img/call-circle-white.svg";
import MailIcon from "../../assets/img/mail-circle-white.svg";
import WebIcon from "../../assets/img/web-circle-white.svg";
import LocationIcon from "../../assets/img/location.png";
import RenderToast from "../../common/RenderToast";

const LetsConnect = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid.";
    }
    if (!formData.phone) newErrors.phone = "Phone number is required.";
    if (!formData.message) newErrors.message = "Message is required.";
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});
    setIsSubmitting(true);

    // Replace these with your actual EmailJS credentials
    const serviceID = "service_op50s0q";
    const templateID = "template_ngzllif";
    const userID = "Ea7b6kSh3WkNCOmE3";

    emailjs.send(serviceID, templateID, formData, userID).then(
      (response) => {
        //console.log("Email sent successfully:", response);
        setIsSubmitting(false);
        setIsSubmitted(true);
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
        setTimeout(() => {
          setIsSubmitted(false)
        }, 3000);
      },
      (error) => {
        console.log("Error sending email:", error);
        setIsSubmitting(false);
      }
    );
  };

  return (
    <div className="lets-connect" id="letsConnect">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-4 address">
            <h4>Address</h4>
            <br />
            <div className="d-flex gap-2 text-white py-3">
              <i class="bi bi-geo-alt-fill"></i>
              <p className="text-white">
                The HUBE
                <br />
                84 Sophia Street, Fairland
                <br />
                2195, South Africa
              </p>
            </div>
            <br />
            <div className="d-flex gap-2 text-white py-3">
              <i class="bi bi-geo-alt-fill"></i>
              <p className="text-white">
                JB-1-C T/F R/S
                <br />
                Khirki Extn, New Delhi, Delhi
                <br />
                India, 110017
              </p>

            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-4 left-panel">
            <h4>Let's Connect</h4>
            <ul className="common-list">
              <li>
                <img src={CallIcon} alt="call icon" />
                <span>
                  <a href="tel:+919999261633">+91-99992 61633</a>
                </span>
              </li>
              <li>
                <img src={MailIcon} alt="mail icon" />
                <a href="mailto:sales@clinchinfosystems.com">
                  sales@clinchinfosystems.com
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-12 col-lg-4 right-panel">
            <div className="contact-form">
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-4">
                  <RenderInput
                    name="name"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    isInvalid={!!errors.name}
                  />
                  {errors.name && (
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="mb-4">
                  <RenderInput
                    name="email"
                    placeholder="Work Email Address"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    type="email"
                    isInvalid={!!errors.email}
                  />
                  {errors.email && (
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="mb-4">
                  <RenderInput
                    name="phone"
                    placeholder="Phone Number"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    isInvalid={!!errors.phone}
                  />
                  {errors.phone && (
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="mb-4">
                  <RenderTextarea
                    name="message"
                    placeholder="Tell us about your requirements"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    isInvalid={!!errors.message}
                  />
                  {errors.message && (
                    <Form.Control.Feedback type="invalid">
                      {errors.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Button
                  variant="outline-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Sending..." : "Submit"}
                </Button>
              </Form>
            </div>
            {isSubmitted && <RenderToast />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LetsConnect;
